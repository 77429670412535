import type { ApolloError, LazyQueryExecFunction } from '@apollo/client';
import type { SearchProductsQuery } from '@ecomm/commercetools/queries/Warranties.generated';
import type {
  Exact,
  Maybe,
  SearchFilterInput,
} from '@ecomm/commercetools/types.generated';
import type { MutationUpdateCartArgs } from '@ecomm/graphql/types.generated';
import type { GetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import type { BundleObject, CfuCTPackage, ItemType, Type } from '../types';

export enum RemoveType {
  SINGLE,
  BUNDLE,
}

export type RemoveItemFromCart = {
  id: string;
  type: RemoveType;
  isBundleCFU: boolean;
  shopCartData: GetShopCartJupiterQuery | undefined;
  refetchCart: Function;
  updateCartMutation: Function;
  searchProductsQuery: LazyQueryExecFunction<
    SearchProductsQuery,
    Exact<{
      filters?: Maybe<SearchFilterInput[]>;
    }>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<undefined | ApolloError>>;
  quantity: number;
};

export type RemovePayloadType = {
  variables: MutationUpdateCartArgs;
};

export type BundleRemovalPayloadType = Pick<
  RemoveItemFromCart,
  'isBundleCFU' | 'shopCartData' | 'searchProductsQuery' | 'id'
>;

export type AddItemToCart = {
  type: Type;
  sku?: string;
  bundleObject?: BundleObject;
  cfuPackage?: CfuCTPackage;
  callBack: Function;
  item?: ItemType;
  quantity?: number;
  shouldOpenCartPanel?: boolean;
};
