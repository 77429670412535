import { useState } from 'react';
import { toCountryFromLocale, toCurrency, useLocale } from '@peloton/internationalize';
import { getCountryCart } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import { useCommercetoolsClient } from '@ecomm/commercetools/apollo';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { useSearchProductsLazyQuery } from '@ecomm/commercetools/queries/Warranties.generated';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import { GetShopCartJupiterDocument } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import type { RemoveType } from './types';
import { removeItemFromCart } from './utils';

/**
 * Hook to remove items from the cart.
 * @returns An object containing the `removeItemFromCart` function and the current `loading` and `error` states.
 */
export const useRemoveItemFromCart = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const locale = useLocale();
  const country = toCountryFromLocale(locale);
  const currency = toCurrency(country);
  const client = useCommercetoolsClient();

  const [updateCartMutation] = useUpdateShopCartJupiterMutation({
    refetchQueries: [
      { query: GetShopCartJupiterDocument, variables: { country: getCountryCart() } },
    ],
    awaitRefetchQueries: true,
  });
  const { refetchCart, shopCartResult: shopCartData } = useGetCartItems();
  const [searchProductsQuery] = useSearchProductsLazyQuery({
    client,
    fetchPolicy: 'no-cache',
    variables: {
      locale,
      country,
      currency,
    },
  });

  const draftRemoveItemFromCart = ({
    id,
    type,
    isBundleCFU,
    quantity,
  }: {
    id: string;
    type: RemoveType;
    isBundleCFU: boolean;
    quantity?: number;
  }) => {
    removeItemFromCart({
      id,
      type,
      isBundleCFU,
      shopCartData,
      refetchCart,
      updateCartMutation,
      searchProductsQuery,
      setLoading,
      setError,
      quantity: quantity ? quantity : 1,
    });
  };

  return { removeItemFromCart: draftRemoveItemFromCart, result: { loading, error } };
};
