import type { LazyQueryExecFunction } from '@apollo/client';
import {
  WARRANTY,
  getCartIncludesHaulaway,
  getCartItemsExcludingId,
  isCartEligibleForHaulaway,
  isUnavailableProductExists,
  isUnavailableProductExistsInBundles,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import mapSearchProductsResponseToUpsellVariants from '@ecomm/commercetools/mappers/mapSearchProductsResponseToUpsellVariants';
import type { SearchProductsQuery } from '@ecomm/commercetools/queries/Warranties.generated';
import type {
  SearchFilterInput,
  Exact,
  Maybe,
} from '@ecomm/commercetools/types.generated';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import type { ShopCartUpdateAction } from '@ecomm/graphql/types.generated';
import logAction from '@ecomm/logging/logAction';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';

/**
 * Checks if all products in the cart are available.
 * @param cartData - The cart data to check.
 * @returns `true` if all products are available, else `false`.
 */
export const checkIfAllProductsAreAvailable = (cartData: any) => {
  const lineItems = cartData?.lineItems ?? [];
  return !(
    isUnavailableProductExists(lineItems) ||
    isUnavailableProductExistsInBundles(lineItems)
  );
};

/**
 * Creates an action to remove an extended warranty from the cart if applicable.
 * @param shopCart - The current shop cart.
 * @param searchProductsQuery - The lazy query function to search products.
 * @param lineItemId - The ID of the line item being removed.
 * @returns A ShopCartUpdateAction or undefined.
 */
export const createRemoveExtendedWarrantyAction = async (
  shopCart: CtCartFragment,
  searchProductsQuery: LazyQueryExecFunction<
    SearchProductsQuery,
    Exact<{
      filters?: Maybe<SearchFilterInput[]>;
    }>
  >,
  lineItemId: string,
): Promise<ShopCartUpdateAction | undefined> => {
  const extendedWarrantiesInCart = shopCart.lineItems.filter(
    lineItem => lineItem.productVariant.type === WARRANTY,
  );

  if (!extendedWarrantiesInCart.length) {
    return undefined;
  }

  const equipmentBundleInCart = shopCart.lineItems.find(
    lineItem => lineItem.id === lineItemId,
  );
  const limitedWarrantyInCart = equipmentBundleInCart?.bundleItemLineItems?.find(
    lineItem => lineItem.productVariant.type === WARRANTY,
  );

  if (limitedWarrantyInCart?.productKey) {
    try {
      const { data: queryResult } = await searchProductsQuery({
        variables: {
          filters: [
            {
              model: {
                value: {
                  path: 'key',
                  values: [limitedWarrantyInCart.productKey],
                },
              },
            },
          ],
        },
      });

      if (queryResult) {
        const productVariants = mapSearchProductsResponseToUpsellVariants(
          queryResult,
          limitedWarrantyInCart.productKey,
        );
        const extendedWarrantySkus = productVariants
          .map(variant => variant?.sku)
          .filter(Boolean);

        const matchingExtendedWarranty = extendedWarrantiesInCart.find(lineItem =>
          extendedWarrantySkus.includes(lineItem.productVariant?.sku),
        );

        if (matchingExtendedWarranty) {
          const equipmentCount = shopCart.lineItems.filter(
            lineItem =>
              lineItem.productVariant.legacyEquipmentType ===
              equipmentBundleInCart?.productVariant.legacyEquipmentType,
          ).length;

          if (matchingExtendedWarranty.quantity >= equipmentCount) {
            return {
              removeLineItem: {
                lineItemId: matchingExtendedWarranty.id,
                quantity: 1,
              },
              actionType: ShopCartUpdateActionType.RemoveLineItem,
            };
          }
        }
      }
    } catch (e) {
      logAction('Error removing extended warranties from cart');
    }
  }

  return undefined;
};

/**
 * Creates an action to remove a haulaway item from the cart if applicable.
 * @param shopCart - The current shop cart.
 * @param id - The ID of the line item being removed.
 * @returns A ShopCartUpdateAction or null.
 */
export const createRemoveHaulawayAction = (
  { lineItems = [] }: CtCartFragment,
  id: string,
) => {
  const { haulawayItem } = getCartIncludesHaulaway(lineItems);
  const cartWithoutLatestRemoval = getCartItemsExcludingId({
    lineItems,
    id,
  });

  if (!isCartEligibleForHaulaway(cartWithoutLatestRemoval) && haulawayItem) {
    return {
      removeLineItem: {
        lineItemId: haulawayItem.id,
        quantity: 1,
      },
      actionType: ShopCartUpdateActionType.RemoveLineItem,
    };
  }

  return null;
};
