import { DiscountType } from '@ecomm/shop-cart/graphql/types.generated';
import type { ShopDiscount } from '@ecomm/shop-cart/graphql/types.generated';

export const SINGLE_CODE_PARAM = 'singleCode';
export const SINGLE_CODE_STORAGE_KEY = 'ecomm/singleCode';

export const hasSingleDiscount = (discounts: ShopDiscount[]) => {
  return discounts.some(discount => {
    return discount.discountType === DiscountType.UserAppliedDiscount;
  });
};
