import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { useRestClientAndUpdateMutation } from './useRestClientAndUpdateMutation';

/**
 * Hook to fetch and manage CT cart data.
 * @returns An object containing `refetchCart`, `shopCartData`, and `updateCartMutation`.
 */
export const useCTCartData = () => {
  const { updateCartMutation } = useRestClientAndUpdateMutation();
  const { refetchCart, shopCartResult: shopCartData } = useGetCartItems();

  return {
    refetchCart,
    shopCartData,
    updateCartMutation,
  };
};
