import type { ApolloQueryResult, QueryOptions } from '@apollo/client';
import type { OperationVariables } from 'react-apollo';
import { Locale as IntlLocale } from '@peloton/internationalize/models';
import { Store } from '@peloton/stores/WebStore';
import type { CatalogClient } from '@ecomm/build-time-catalog';
import { Locale } from '@ecomm/graphql/types.generated';
import { MembershipSku } from '@ecomm/membership/models';
import { MembershipPlansQuery } from '../queries/MembershipPlansQuery';
import type { CatalogQueryVars, MembershipPlanData } from '../types';
import fetchPricingDataCT from './fetchPricingDataCT';
import type { LocaleProductPrices } from './getProductPricesUtils';

/** This is stuck here due to Graphql constraints and can't be easily uplifted to consolidatedAppConfigs */
export const toIntlLocale = (locale: Locale): IntlLocale =>
  ({
    [Locale.DeAt]: IntlLocale.GermanAustria,
    [Locale.DeDe]: IntlLocale.GermanGermany,
    [Locale.EnAu]: IntlLocale.EnglishAustralia,
    [Locale.EnCa]: IntlLocale.EnglishCanada,
    [Locale.EnGb]: IntlLocale.EnglishUnitedKingdom,
    [Locale.EnUs]: IntlLocale.EnglishUnitedStates,
  }[locale]);

export const getMembershipPricesData = async (
  client: CatalogClient,
): Promise<Record<Store, MembershipPlanData>> => {
  const responses: Array<Promise<ApolloQueryResult<MembershipPlanData>>> = [];
  const stores = Object.values(Store);

  if (stores.length < 0) {
    return {} as Record<Store, MembershipPlanData>;
  }

  stores.forEach(store => {
    responses.push(
      (client.query as <T = any, TVariables = OperationVariables>(
        options: QueryOptions<TVariables>,
      ) => Promise<ApolloQueryResult<T>>)({
        query: MembershipPlansQuery,
        variables: {
          storeSlug: store,
          allAccessMembershipSku: MembershipSku.AllAccess,
          guideMembershipSku: MembershipSku.Guide,
          appOneMembershipSku: MembershipSku.AppOne,
          appPlusMembershipSku: MembershipSku.AppPlus,
        },
      }),
    );
  });
  const membershipData = await Promise.all(responses);

  const result = {};

  stores.forEach((store, i) => {
    result[store] = {
      ...membershipData[i].data,
    };
  });
  return result as Record<Store, MembershipPlanData>;
};

export const getStorePrices = async (
  client: CatalogClient,
  store: CatalogQueryVars,
): Promise<Partial<LocaleProductPrices | undefined>> => {
  const { locale, currency } = store;
  const intLocale = toIntlLocale(locale);
  console.log(
    `Fetching product prices from Commercetools in ${process.env.APP_CONFIG_PRESET} environment`,
  );
  return await fetchPricingDataCT(intLocale, currency);
};
