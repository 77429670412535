import type { ApolloError } from '@apollo/client';
import {
  getCountryCart,
  getPrepaidAAMProduct,
  getRegularAAMProduct,
  hasGiftInformation,
  isCartEligibleForGifting,
  isCartEligibleForPrepaidMembership,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import logAction from '@ecomm/logging/logAction';
import {
  createRemoveExtendedWarrantyAction,
  createRemoveHaulawayAction,
} from './helpers';
import { RemoveType } from './types';
import type {
  BundleRemovalPayloadType,
  RemoveItemFromCart,
  RemovePayloadType,
} from './types';

/**
 * Creates a payload for removing a single item from the cart.
 * @param quantity - The quantity of the item to remove.
 * @param lineItemId - The ID of the line item to remove.
 * @returns The payload object.
 */
export const getSingleItemRemovalPayload = ({
  quantity,
  lineItemId,
}: {
  quantity: number;
  lineItemId: string;
}) => ({
  variables: {
    input: {
      country: getCountryCart(),
      actions: [
        {
          removeLineItem: {
            lineItemId,
            quantity,
          },
          actionType: ShopCartUpdateActionType.RemoveLineItem,
        },
      ],
    },
  },
});

/**
 * Creates a payload for removing a bundle item from the cart.
 * @param params - The parameters required to create the payload.
 * @returns The payload object.
 */
export const getBundleItemRemovalPayload = async ({
  isBundleCFU,
  shopCartData,
  searchProductsQuery,
  id,
}: BundleRemovalPayloadType) => {
  const actions = [];

  // Check if CFU bundle, then remove the regular AAM (All-Access-Membership) with qty:1
  if (isBundleCFU) {
    const regularMembership = getRegularAAMProduct(
      shopCartData?.shopCart?.lineItems ?? [],
    );

    if (regularMembership) {
      actions.push({
        removeLineItem: {
          lineItemId: regularMembership?.id ?? '',
          quantity: 1,
        },
        actionType: ShopCartUpdateActionType.RemoveLineItem,
      });
    }
  }

  // Remove prepaid memberships if no bikes/treads/rows left in cart after bundle is removed
  const isCartEligible = isCartEligibleForPrepaidMembership(
    shopCartData?.shopCart?.lineItems?.filter(item => item.id !== id) ?? [],
  );
  const prepaidMembership = getPrepaidAAMProduct(shopCartData?.shopCart?.lineItems ?? []);

  if (prepaidMembership && !isCartEligible) {
    actions.push({
      removeLineItem: {
        lineItemId: prepaidMembership.id,
        quantity: 1,
      },
      actionType: ShopCartUpdateActionType.RemoveLineItem,
    });
  }

  // Set isGift to false if no eligible items left
  const isEligibleForGifting = isCartEligibleForGifting(
    shopCartData?.shopCart?.lineItems?.filter(item => item.id !== id) ?? [],
    shopCartData?.shopCart?.locale ?? '',
  );

  if (!isEligibleForGifting && shopCartData?.shopCart?.isGift) {
    actions.push({
      setIsGift: {
        isGift: false,
      },
      actionType: ShopCartUpdateActionType.SetIsGift,
    });

    if (hasGiftInformation(shopCartData?.shopCart)) {
      actions.push({
        setGiftInformation: {
          giftMessage: '',
          gifterName: '',
          recipientEmail: '',
          recipientName: '',
        },
        actionType: ShopCartUpdateActionType.SetGiftInformation,
      });
    }
  }

  // Remove the bundle item
  actions.push({
    removeBundleItem: {
      lineItemId: id,
    },
    actionType: ShopCartUpdateActionType.RemoveBundleItem,
  });

  // Additional removals if necessary
  if (shopCartData?.shopCart) {
    // Remove extended warranty if no eligible SKUs remain in cart
    const removeWarrantyAction = await createRemoveExtendedWarrantyAction(
      shopCartData.shopCart,
      searchProductsQuery,
      id,
    );

    if (removeWarrantyAction) actions.push(removeWarrantyAction);

    // Remove haulaway item if no eligible SKUs remain in cart
    const removeHaulawayAction = createRemoveHaulawayAction(shopCartData.shopCart, id);

    if (removeHaulawayAction) {
      actions.push(removeHaulawayAction);
    }
  }

  return {
    variables: {
      input: {
        country: getCountryCart(),
        actions,
      },
    },
  };
};

/**
 * Removes an item from the cart based on the provided parameters.
 * @param params - The parameters required to remove the item.
 */
export const removeItemFromCart = async ({
  id,
  type,
  isBundleCFU,
  shopCartData,
  refetchCart,
  updateCartMutation,
  searchProductsQuery,
  setError,
  setLoading,
  quantity,
}: RemoveItemFromCart) => {
  setLoading(true);
  let removePayload = {} as RemovePayloadType;
  switch (type) {
    case RemoveType.SINGLE: {
      removePayload = getSingleItemRemovalPayload({ quantity, lineItemId: id });
      break;
    }
    case RemoveType.BUNDLE: {
      removePayload = await getBundleItemRemovalPayload({
        id,
        isBundleCFU,
        shopCartData,
        searchProductsQuery,
      });
      break;
    }
  }
  const numberOfItemsInCart = shopCartData?.shopCart?.lineItems?.length || 0;

  const numberOfItemsToRemove =
    removePayload.variables?.input?.actions.filter(action => {
      return (
        action.actionType == ShopCartUpdateActionType.RemoveBundleItem ||
        action.actionType == ShopCartUpdateActionType.RemoveLineItem
      );
    }).length || 0;

  if (numberOfItemsInCart === numberOfItemsToRemove) {
    for (const discount of shopCartData?.shopCart?.discounts ?? []) {
      // Check if the coupon is a non-auto-apply coupon
      if (discount.discountCode.code.length) {
        removePayload.variables?.input?.actions.unshift({
          removeDiscount: {
            id: discount.discountCode.id,
          },
          actionType: ShopCartUpdateActionType.RemoveDiscount,
        });
      }
    }
  }

  updateCartMutation(removePayload)
    .then(() => {
      setLoading(false);
      logAction('success removing item from cart', {
        payload: removePayload,
        type,
        id,
      });
      // TODO: send result back => call onSuccess callback : Yet to decide on this
    })
    .catch((e: ApolloError) => {
      // TODO: Add TO Cart Failed
      logAction('error removing item from cart', {
        error: e,
        id,
        type,
      });
      console.error(e);
      setError(e);
      // Refetching the CT cart to get the most up-to-date cart from BE to account for the multi-tab issue.
      refetchCart();
    })
    .finally(() => {
      setLoading(false);
    });
};
