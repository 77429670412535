import { useClient } from '@peloton/api/ClientContext';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';

/**
 * Hook to provide the REST client and the `updateCartMutation` function.
 * @returns An object containing `restClient` and `updateCartMutation`.
 */
export const useRestClientAndUpdateMutation = () => {
  const restClient = useClient();
  const [updateCartMutation] = useUpdateShopCartJupiterMutation();

  return {
    restClient,
    updateCartMutation,
  };
};
