import { useErrorReporter } from '@peloton/error-reporting';
import { useCartQuery } from '@ecomm/cart-next/graphql/queries/Cart.generated';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

/**
 * Hook to refetch the Monolith cart.
 * @returns An object containing `data` and `refetchMonolithCart` function.
 */
export const useMonolithCartRefetch = () => {
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const { errorReporter } = useErrorReporter();
  const { refetch, data } = useCartQuery({
    variables: { calculateEstimatedShippingPrice: isProjectPhoenixEnabled },
    suspend: false,
    notifyOnNetworkStatusChange: true,
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });

  return { data, refetchMonolithCart: refetch };
};
