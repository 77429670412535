import type { ApolloClient } from '@apollo/client';
import { toCountryFromLocale } from '@peloton/internationalize';
import { SearchProductByKeyDocument } from '@ecomm/commercetools/queries/SearchProductByKey.generated';
import type { Locale } from '@ecomm/graphql/types.generated';
import { toIntlLocale } from '@ecomm/pricing/helpers/extractTransform';

export const getCtSubscriptionProductVariantId = async (
  ctClient: ApolloClient<any>,
  productKey: string,
  locale: string,
  currency: string,
): Promise<string | undefined> => {
  const country = toCountryFromLocale(locale);
  const normalLocale = toIntlLocale(locale as Locale);

  const result = await ctClient.query({
    query: SearchProductByKeyDocument,
    variables: {
      where: `key="${productKey}"`,
      acceptLanguage: normalLocale,
      currency,
      country,
    },
    fetchPolicy: 'cache-first',
  });

  return result.data?.products?.results?.[0]?.masterData?.current?.masterVariant?.attributesRaw?.find(
    (attr: { name: string }) => attr.name === 'legacy-option-id',
  )?.value;
};
