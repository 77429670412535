import {
  useLocale,
  ELIGIBLE_LOCALES_FOR_GIFTING_ENHANCEMENTS,
} from '@peloton/internationalize';
import {
  getPrepaidAAMProduct,
  hasGiftInformation,
  getCountryCart,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import type { ShopCartUpdateAction } from '@ecomm/graphql/types.generated';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import logAction from '@ecomm/logging/logAction';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import { useSetCartStatusLoading, useSetCartStatusReady } from '../state/checkoutStore';

type SetIsGiftOnCartInput = {
  shopCart: CtCartFragment;
  isGift: boolean;
};

const useSetIsGiftOnCart = () => {
  const [updateCartMutation] = useUpdateShopCartJupiterMutation();
  const setCartStatusLoading = useSetCartStatusLoading();
  const setCartStatusReady = useSetCartStatusReady();
  const locale = useLocale();

  return async ({ shopCart, isGift }: SetIsGiftOnCartInput) => {
    setCartStatusLoading();

    try {
      const actions: ShopCartUpdateAction[] = [
        {
          setIsGift: {
            isGift,
          },
          actionType: ShopCartUpdateActionType.SetIsGift,
        },
      ];

      if (!isGift) {
        // If a gift cart has null gift information, which is possible in Canada,
        // we don't want to set the gift information to empty strings
        if (hasGiftInformation(shopCart)) {
          actions.push({
            setGiftInformation: {
              giftMessage: '',
              gifterName: '',
              recipientEmail: '',
              recipientName: '',
            },
            actionType: ShopCartUpdateActionType.SetGiftInformation,
          });
        }

        const prepaidMembership = getPrepaidAAMProduct(shopCart?.lineItems ?? []);
        if (prepaidMembership) {
          actions.push({
            removeLineItem: {
              lineItemId: prepaidMembership.id,
              quantity: prepaidMembership.quantity,
            },
            actionType: ShopCartUpdateActionType.RemoveLineItem,
          });
        }
      }

      const shouldCreateGiftInformation =
        isGift && ELIGIBLE_LOCALES_FOR_GIFTING_ENHANCEMENTS.includes(locale);
      if (shouldCreateGiftInformation && shopCart.shippingAddress?.firstName) {
        actions.push({
          setGiftInformation: {
            recipientName: shopCart.shippingAddress.firstName,
          },
          actionType: ShopCartUpdateActionType.SetGiftInformation,
        });
      }

      await updateCartMutation({
        variables: {
          input: {
            country: getCountryCart(),
            actions,
          },
        },
      });
    } catch (e) {
      logAction('failed to update isGift on cart');
    }

    setCartStatusReady();
  };
};

export default useSetIsGiftOnCart;
